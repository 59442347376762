<template>
  <!--许愿池列表 -->
  <div class="wishing-well-list-container">
    <img
      class="wishing-well-list-bg"
      src="../../assets/icon/christmas/list-bg.png"
    />
    <!-- 标题轮播 -->
    <van-notice-bar
      v-if="bannerListText"
      ref="noticeBar"
      class="gashpon-banner"
      :speed="50"
      height="100%"
      :scrollable="true"
      :text="bannerListText"
    ></van-notice-bar>

    <!-- 场次 -->
    <div class="wishing-well-list-content">
      <div
        class="wishing-well-list-item"
        v-for="(i,j) in wishingwellList"
        :key="j"
        @click="goDetails(i)"
      >
        <div class="row-start">
          <div class="wishing-well-list-item-img row-center">
            <img :src="require('../../assets/icon/christmas/list-img.png')" />
          </div>

          <div class="col-start">
            <p class="wishing-well-list-item-title">{{ i.name }}</p>
            <p class="wishing-well-list-item-luck">{{i.targetLuckyNum}}幸运值</p>
            <p class="wishing-well-list-item-type">
              <span :style="i.status == 0 || i.status == 1 ? 'margin-right: 10px;' : ''">{{ i.status == 0 ? '开始时间' : i.status == 1 ? '距离开奖' : ''  }}</span>
              <span style="color: #ef6439;">{{ i.status == 0 ? i.startTime : i.status == 1 ? i.countdown : '已开奖'  }}</span>

            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Cookies from 'js-cookie'
import api from '../../api/index-client'
import { NoticeBar } from 'vant';
Vue.use(NoticeBar);
export default {
  data () {
    return {
      wishingWellListTitle: '幸运夺宝，奖品多多',
      wishingwellList: [],
      bannerListText: ''
    }
  },
  mounted () {
    this.getWishingwellList()
  },
  methods: {
    // 获取场次列表
    getWishingwellList () {
      api
        .get('wishingwell/list')
        .then(result => {
          if (result.data.success) {
            this.wishingwellList = result.data.data
            this.getGashaponBanner()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取走马灯数据
    getGashaponBanner () {
      api
        .get('wishingwell/getMarquee')
        .then(result => {
          if (result.data.success) {
            console.log(result);
            if (result.data.data) {
              this.bannerListText = ''
              result.data.data.map(i => {
                var text = i.phone + '中奖啦 ' + i.create_time
                if (i == result.data.data.length - 1) {
                  this.bannerListText += text
                } else {
                  this.bannerListText += text + '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'
                }
              })
            }
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    goDetails (e) {
      this.$router.push('/wishingWellDetails?code=' + e.code)
    },
  },
}
</script>
<style lang="less" scoped>
.wishing-well-list-container {
  width: 100vw;
  height: 100vh;
  background: #ff876c;
  position: relative;
  .wishing-well-list-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .gashpon-banner {
    width: 65vw;
    height: 7.5vw;
    background-image: url('../../assets/icon/gashapon/gashpon-banner.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin-left: 17.5vw;
    position: absolute;
    top: 40vh;
    z-index: 100;
  }
  .wishing-well-list-content {
    z-index: 100;
    width: 90vw;
    margin-left: 5vw;
    position: absolute;
    top: 47vh;
    height: 50vh;
    background: #fff5e9;
    border-radius: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    padding-bottom: 15px;
    .wishing-well-list-item {
      width: 84vw;
      margin-top: 15px;
      height: 100px;
      padding: 10px 0;
      margin-left: 3vw;
      border-radius: 10px;
      background: #ffeccb;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .wishing-well-list-item-img {
        width: 80px;
        height: 80px;
        background: #fff6ea;
        border-radius: 10px;
        margin-left: 10px;
        img {
          width: 70px;
          height: auto;
        }
      }
      .wishing-well-list-item-title {
        width: calc(84vw - 105px);
        color: #ef6439;
        font-size: 16px;
        font-weight: 400;
        margin-left: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .wishing-well-list-item-luck {
        color: #544d43;
        font-size: 14px;
        font-weight: 400;
        margin: 5px 0 5px 15px;
      }

      .wishing-well-list-item-type {
        color: #544d43;
        font-size: 16px;
        font-weight: 400;
        margin-left: 15px;
      }
      .wishing-well-list-next {
        width: auto;
        height: 12px;
        margin-right: 10px;
      }
    }
  }
}
/deep/.van-notice-bar {
  color: #ffffff;
  padding: 0;
  background-color: transparent;
}
/deep/.van-notice-bar__wrap {
  flex: initial;
  width: 94%;
  margin-left: 3%;
}
</style>